.rc-checkbox-inner {
    width: 16px;
    height: 16px;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
    transform: rotate(45deg);
    position: absolute;
    left: 5px;
    top: 1px;
    display: table;
    width: 5px;
    height: 10px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}
