.drawer-handle {
    right: 0 !important;
    z-index: 10;
    box-shadow: none !important;
    background: #fff !important;
}

.drawer-open .drawer-handle {
    top: 0;
    background: #f1f1f1;
    right: 0;
    height: 53px;
}

.drawer-header {
    padding: 10px 1.25rem;
    margin-bottom: 0;
    color: #555;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawer-header img {
    height: 35px;
    cursor: pointer;
    padding: 5px 10px;
}

.drawer-open .drawer-header {
    position: relative;
    padding: 16px 24px;
    background: #fff !important;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.drawer-open .drawer-body {
    padding: 10px 30px;
}

.drawerWrapper {
    background: black;
}

.drawer-right .drawer-handle {
    position: absolute;
    left: auto !important;
    right: 10px !important;
}

.drawer-close {
}

.drawer-close:after {
    content: "X";
}
