.rc-dialog-mask {
    /*z-index: 10001;*/
}

.rc-dialog-wrap {
    /*z-index: 10002;*/
}

.rc-dialog-header {
    padding: 16px 24px !important;
    color: rgba(0, 0, 0, .65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
}

.rc-dialog-title {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

.rc-dialog-close {
    opacity: 0.6 !important;
}

.rc-dialog-close-x {
    width: 56px;
    height: 56px;
    font-style: normal;
    font-weight: 500;

}

.rc-dialog-close-x:after {
    content: '×';
    color: black !important;
    font-size: 32px;
}

.rc-dialog-content {
    max-height: 90vh;
    overflow: auto;
}

.confirmModal .rc-dialog-content {
    height: 100%;
}

.confirmModal .rc-dialog-content .rc-dialog-close {
    display: none;
}

.confirmModal .rc-dialog-header {
    padding: 0 0 10px 0 !important;

}

.confirmModal .rc-dialog-body {
    text-align: right;
    padding: 10px 0 0 0 !important;
}

.confirmModal .rc-dialog-body .btn:first-child {
    margin-right: 10px;
}
